import { Injectable } from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbMenuService, NbToastrConfig,
  NbToastrService } from '@nebular/theme';
import { MENU_ITEMS } from '../../../pages/pages-menu';

@Injectable({
  providedIn: 'root',
})
export class UiService {

  menu = MENU_ITEMS;
  config: NbToastrConfig;

  destroyByClick = true;
  duration = 3500;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  constructor(
    private toastrService: NbToastrService,
    private menuService: NbMenuService,
  ) { }

  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `${title}` : '';

    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

  navigateHome() {
    this.menuService.collapseAll();
    this.menuService.navigateHome();
    this.menu.forEach(item => {
      item.selected = false;

      if (item.children) {
        item.children.forEach(child => {
          child.selected = false;
        });
      }
    });
  }
}
