import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <div class="row created-by">
        <div class="col">
          <strong>Dirección: </strong>Av. Baltra S/N diagonal a la gruta del divino niño - Santa Cruz (Galápagos - Ecuador)
        </div>
        <div class="col-auto">
          <strong>Teléfono: </strong> +593-5 2527414 ext 131
        </div>
        <!-- <div class="col-auto">
          <p class="text-right">v<strong>1.0.0</strong></p>
        </div> -->
      </div>
    </span>
    <div class="socials">
      <p class="my-auto text-right">v<strong>{{ version }}</strong></p>
      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a> -->
    </div>
  `,
})
export class FooterComponent {

  version: string;

  constructor() {
    this.version = environment.version;
  }
}
